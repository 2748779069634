import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'; 

const RichEditor = ({ value, onChange }) => {
  return (
    <ReactQuill
      value={value}
      onChange={onChange}
      modules={{
        toolbar: [
          [{ 'header': [1, 2, 3, 4, 5, 6, false] }], 
          [{ 'font': [] }],
          [{ 'list': 'ordered' }, { 'list': 'bullet' }],
          ['bold', 'italic', 'underline'],
          [{ 'align': [] }],
          ['link', 'image'],
          [{ 'color': [] }, { 'background': [] }],
          [{ 'size': ['small', 'normal', 'large', 'huge'] }],
        ],
      }}
      formats={[
        'header', 'font', 'size', 'list', 'bullet', 'bold', 'italic', 'underline', 'align', 'link', 'image', 'color', 'background'
      ]}
    />
  );
};

export default RichEditor;
