import { NavLink } from "react-router-dom";
import { assets } from "../../assets/assets";
import "./Sidebar.css";
const Sidebar = () => {
  return (
    <div className="sidebar">
      <div className="sidebar-options">
        <NavLink to="/dodaj-samochód" className="sidebar-option">
          <img src={assets.add_icon} alt="" />
          <p>Dodaj Samochód</p>
        </NavLink>
        <NavLink to="/lista-samochodów" className="sidebar-option">
          <img src={assets.order_icon} alt="" />
          <p>Lista Samochodów</p>
        </NavLink>
        <NavLink to="/dodatek-dekoracja" className="sidebar-option">
          <img src={assets.add_icon} alt="" />
          <p>Dodaj Dekoracje</p>
        </NavLink>
        <NavLink to="/listy-dekoracji" className="sidebar-option">
          <img src={assets.order_icon} alt="" />
          <p> Lista Dekoracji</p>
        </NavLink>
      </div>
    </div>
  );
};

export default Sidebar;
