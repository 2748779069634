import logo from "./logo.png";
import add_icon from "./add_icon.png";
import order_icon from "./order_icon.png";
import profile_image from "./profile_image.png";
import upload_area from "./upload_area.png";
import parcel_icon from "./parcel_icon.png";
import adminBanner from "./adminBackgroundH.jpg";
import logoSvg from "./logosvg1.svg";
import logo5 from "./logo5.svg";

export const assets = {
  logo,
  add_icon,
  order_icon,
  profile_image,
  upload_area,
  parcel_icon,
  adminBanner,
  logoSvg,
  logo5,
};
