import Select from "react-select";

const MultiSelect = ({ options, setValue, value }) => {
  const countries = options ;

  const values = countries.map((country) => ({
    value: country,
    label: country,
  }));

  const handleChange = (selectedOptions) => {
    const values = selectedOptions.map((option) => option.value);
    setValue(values.join(","));
  };

  return (
    <Select isMulti options={values} value={value} onChange={handleChange} />
  );
};

export default MultiSelect;
