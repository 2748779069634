import CarEdit from "../../components/CarEdit";
import "./CarEditAndUpdate.css"
const CarEditAndUpdate = () => {
  return (
    <div className="add">
      <CarEdit />
    </div>
  );
};

export default CarEditAndUpdate;
