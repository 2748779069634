import React from "react";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";

export function SortableItem({ id, onRemove }) {
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id: id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  return (
    <div
      className="relative m-2"
      ref={setNodeRef}
      style={style}
      {...attributes}
      {...listeners}
    >
      <img className="h-20" src={id} alt="" />
    </div>
  );
}

{
  /* <button
        type="button"
        className="cursor-default bg-red-500 text-white rounded-full text-center h-6 w-6 absolute top-0 right-0 hover:text-red-700"
        onClick={(event) => {
            console.log("asdf")
          event.stopPropagation(); 
          onRemove(id);
        }}
      >
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="rgba(244,244,244,1)"><path d="M11.9997 10.5865L16.9495 5.63672L18.3637 7.05093L13.4139 12.0007L18.3637 16.9504L16.9495 18.3646L11.9997 13.4149L7.04996 18.3646L5.63574 16.9504L10.5855 12.0007L5.63574 7.05093L7.04996 5.63672L11.9997 10.5865Z"></path></svg>
      </button> */
}
