import { assets } from "../../assets/assets";
import "./Home.css";

const Homepage = () => {
  return (
    <div className="min-h-screen w-full flex justify-start items-start lg:justify-center lg:items-center overflow-hidden">
      <img
        src={assets.adminBanner}
        alt="Descriptive Alt Text"
        className="w-full h-full lg:object-cover object-fill overflow-hidden"
      />
    </div>
  );
};

export default Homepage;
