import CarForm from "../../components/CarForm";
import "./Add.css"
const AddCar = () => {
  return (
    <div className="add">
      <CarForm  />
    </div>
  );
};

export default AddCar;
