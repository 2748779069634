import DecorationForm from "../../components/DecorationForm";
import "./Add.css"
const AddCar = () => {
  return (
    <div className="add">
      <DecorationForm />
    </div>
  );
};

export default AddCar;
