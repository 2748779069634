import DecorEdit from "../../components/DecorEdit";
import "./DecorEditAndUpdate.css";
const DecorEditAndUpdate = () => {
  return (
    <div className="add">
      <DecorEdit />
    </div>
  );
};

export default DecorEditAndUpdate;
