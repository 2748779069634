import React, { useEffect, useState } from "react";
import {
  DndContext,
  closestCenter,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  horizontalListSortingStrategy,
} from "@dnd-kit/sortable";
import { SortableItem } from "./SortableItem";

const Main = ({ images, onSortEnd }) => {
  console.log(images);

  const [items, setItems] = useState(images);

  useEffect(() => {
    setItems(images);
  }, [images]);

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  // Remove item handler
  const removeItem = (id) => {
    setItems((prevItems) => prevItems.filter((item) => item != id));
  };

  function handleDragEnd(event) {
    const { active, over } = event;

    if (active.id !== over.id) {
      setItems((items) => {
        const oldIndex = items.indexOf(active.id);
        const newIndex = items.indexOf(over.id);
        console.log(active.id, over.id);
        const updatedItems = arrayMove(items, oldIndex, newIndex);
        onSortEnd(updatedItems);
        return updatedItems;
      });
    }
  }

  return (
    <DndContext
      sensors={sensors}
      collisionDetection={closestCenter}
      onDragEnd={handleDragEnd}
    >
      <SortableContext items={items} strategy={horizontalListSortingStrategy}>
        <div className="flex flex-wrap">
          {items &&
            items.map((id) => (
              <SortableItem key={id} id={id} onRemove={removeItem} />
            ))}
        </div>
      </SortableContext>
    </DndContext>
  );
};

export default Main;
